import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _ff52ccf0 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _13a9694c = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _f9298ab4 = () => interopDefault(import('../pages/live.vue' /* webpackChunkName: "pages/live" */))
const _775d2c22 = () => interopDefault(import('../pages/live/_hall.vue' /* webpackChunkName: "pages/live/_hall" */))
const _70dcdc60 = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _50c42436 = () => interopDefault(import('../pages/partners.vue' /* webpackChunkName: "pages/partners" */))
const _0a1e29bd = () => interopDefault(import('../pages/program/index.vue' /* webpackChunkName: "pages/program/index" */))
const _8a524dde = () => interopDefault(import('../pages/speakers/index.vue' /* webpackChunkName: "pages/speakers/index" */))
const _0f7f4afd = () => interopDefault(import('../pages/user/index.ts' /* webpackChunkName: "pages/user/index" */))
const _747f1884 = () => interopDefault(import('../pages/users.vue' /* webpackChunkName: "pages/users" */))
const _7c04b4eb = () => interopDefault(import('../pages/auth/change-password.vue' /* webpackChunkName: "pages/auth/change-password" */))
const _630c2b03 = () => interopDefault(import('../pages/auth/impersonalize.vue' /* webpackChunkName: "pages/auth/impersonalize" */))
const _f7847d08 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _ead98b14 = () => interopDefault(import('../pages/auth/registration.vue' /* webpackChunkName: "pages/auth/registration" */))
const _44b2318b = () => interopDefault(import('../pages/auth/registration-iframe.vue' /* webpackChunkName: "pages/auth/registration-iframe" */))
const _369d5fb8 = () => interopDefault(import('../pages/user/calendar.vue' /* webpackChunkName: "pages/user/calendar" */))
const _5e671bd2 = () => interopDefault(import('../pages/user/chat.vue' /* webpackChunkName: "pages/user/chat" */))
const _2c62f402 = () => interopDefault(import('../pages/user/payment/index.vue' /* webpackChunkName: "pages/user/payment/index" */))
const _261dede2 = () => interopDefault(import('../pages/user/profile/index.vue' /* webpackChunkName: "pages/user/profile/index" */))
const _07322751 = () => interopDefault(import('../pages/user/recommended.vue' /* webpackChunkName: "pages/user/recommended" */))
const _4b811fc0 = () => interopDefault(import('../pages/user/payment/participant.vue' /* webpackChunkName: "pages/user/payment/participant" */))
const _22d01dac = () => interopDefault(import('../pages/user/profile/edit.vue' /* webpackChunkName: "pages/user/profile/edit" */))
const _66977eab = () => interopDefault(import('../pages/auth/oauth/_provider.vue' /* webpackChunkName: "pages/auth/oauth/_provider" */))
const _075718c3 = () => interopDefault(import('../pages/news/_alias.vue' /* webpackChunkName: "pages/news/_alias" */))
const _165d7e76 = () => interopDefault(import('../pages/program/_alias.vue' /* webpackChunkName: "pages/program/_alias" */))
const _7cbab9a2 = () => interopDefault(import('../pages/speakers/_alias.vue' /* webpackChunkName: "pages/speakers/_alias" */))
const _c96f02a8 = () => interopDefault(import('../pages/visit/_id.vue' /* webpackChunkName: "pages/visit/_id" */))
const _6401bcd6 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/en",
    component: _ff52ccf0,
    name: "index___en"
  }, {
    path: "/faq",
    component: _13a9694c,
    name: "faq___ru___default"
  }, {
    path: "/live",
    component: _f9298ab4,
    name: "live___ru___default",
    children: [{
      path: ":hall?",
      component: _775d2c22,
      name: "live-hall___ru___default"
    }]
  }, {
    path: "/news",
    component: _70dcdc60,
    name: "news___ru___default"
  }, {
    path: "/partners",
    component: _50c42436,
    name: "partners___ru___default"
  }, {
    path: "/program",
    component: _0a1e29bd,
    name: "program___ru___default"
  }, {
    path: "/ru",
    component: _ff52ccf0,
    name: "index___ru"
  }, {
    path: "/speakers",
    component: _8a524dde,
    name: "speakers___ru___default"
  }, {
    path: "/user",
    component: _0f7f4afd,
    name: "user___ru___default"
  }, {
    path: "/users",
    component: _747f1884,
    name: "users___ru___default"
  }, {
    path: "/auth/change-password",
    component: _7c04b4eb,
    name: "auth-change-password___ru___default"
  }, {
    path: "/auth/impersonalize",
    component: _630c2b03,
    name: "auth-impersonalize___ru___default"
  }, {
    path: "/auth/login",
    component: _f7847d08,
    name: "auth-login___ru___default"
  }, {
    path: "/auth/registration",
    component: _ead98b14,
    name: "auth-registration___ru___default"
  }, {
    path: "/auth/registration-iframe",
    component: _44b2318b,
    name: "auth-registration-iframe___ru___default"
  }, {
    path: "/en/faq",
    component: _13a9694c,
    name: "faq___en"
  }, {
    path: "/en/live",
    component: _f9298ab4,
    name: "live___en",
    children: [{
      path: ":hall?",
      component: _775d2c22,
      name: "live-hall___en"
    }]
  }, {
    path: "/en/news",
    component: _70dcdc60,
    name: "news___en"
  }, {
    path: "/en/partners",
    component: _50c42436,
    name: "partners___en"
  }, {
    path: "/en/program",
    component: _0a1e29bd,
    name: "program___en"
  }, {
    path: "/en/speakers",
    component: _8a524dde,
    name: "speakers___en"
  }, {
    path: "/en/user",
    component: _0f7f4afd,
    name: "user___en"
  }, {
    path: "/en/users",
    component: _747f1884,
    name: "users___en"
  }, {
    path: "/ru/faq",
    component: _13a9694c,
    name: "faq___ru"
  }, {
    path: "/ru/live",
    component: _f9298ab4,
    name: "live___ru",
    children: [{
      path: ":hall?",
      component: _775d2c22,
      name: "live-hall___ru"
    }]
  }, {
    path: "/ru/news",
    component: _70dcdc60,
    name: "news___ru"
  }, {
    path: "/ru/partners",
    component: _50c42436,
    name: "partners___ru"
  }, {
    path: "/ru/program",
    component: _0a1e29bd,
    name: "program___ru"
  }, {
    path: "/ru/speakers",
    component: _8a524dde,
    name: "speakers___ru"
  }, {
    path: "/ru/user",
    component: _0f7f4afd,
    name: "user___ru"
  }, {
    path: "/ru/users",
    component: _747f1884,
    name: "users___ru"
  }, {
    path: "/user/calendar",
    component: _369d5fb8,
    name: "user-calendar___ru___default"
  }, {
    path: "/user/chat",
    component: _5e671bd2,
    name: "user-chat___ru___default"
  }, {
    path: "/user/payment",
    component: _2c62f402,
    name: "user-payment___ru___default"
  }, {
    path: "/user/profile",
    component: _261dede2,
    name: "user-profile___ru___default"
  }, {
    path: "/user/recommended",
    component: _07322751,
    name: "user-recommended___ru___default"
  }, {
    path: "/en/auth/change-password",
    component: _7c04b4eb,
    name: "auth-change-password___en"
  }, {
    path: "/en/auth/impersonalize",
    component: _630c2b03,
    name: "auth-impersonalize___en"
  }, {
    path: "/en/auth/login",
    component: _f7847d08,
    name: "auth-login___en"
  }, {
    path: "/en/auth/registration",
    component: _ead98b14,
    name: "auth-registration___en"
  }, {
    path: "/en/auth/registration-iframe",
    component: _44b2318b,
    name: "auth-registration-iframe___en"
  }, {
    path: "/en/user/calendar",
    component: _369d5fb8,
    name: "user-calendar___en"
  }, {
    path: "/en/user/chat",
    component: _5e671bd2,
    name: "user-chat___en"
  }, {
    path: "/en/user/payment",
    component: _2c62f402,
    name: "user-payment___en"
  }, {
    path: "/en/user/profile",
    component: _261dede2,
    name: "user-profile___en"
  }, {
    path: "/en/user/recommended",
    component: _07322751,
    name: "user-recommended___en"
  }, {
    path: "/ru/auth/change-password",
    component: _7c04b4eb,
    name: "auth-change-password___ru"
  }, {
    path: "/ru/auth/impersonalize",
    component: _630c2b03,
    name: "auth-impersonalize___ru"
  }, {
    path: "/ru/auth/login",
    component: _f7847d08,
    name: "auth-login___ru"
  }, {
    path: "/ru/auth/registration",
    component: _ead98b14,
    name: "auth-registration___ru"
  }, {
    path: "/ru/auth/registration-iframe",
    component: _44b2318b,
    name: "auth-registration-iframe___ru"
  }, {
    path: "/ru/user/calendar",
    component: _369d5fb8,
    name: "user-calendar___ru"
  }, {
    path: "/ru/user/chat",
    component: _5e671bd2,
    name: "user-chat___ru"
  }, {
    path: "/ru/user/payment",
    component: _2c62f402,
    name: "user-payment___ru"
  }, {
    path: "/ru/user/profile",
    component: _261dede2,
    name: "user-profile___ru"
  }, {
    path: "/ru/user/recommended",
    component: _07322751,
    name: "user-recommended___ru"
  }, {
    path: "/user/payment/participant",
    component: _4b811fc0,
    name: "user-payment-participant___ru___default"
  }, {
    path: "/user/profile/edit",
    component: _22d01dac,
    name: "user-profile-edit___ru___default"
  }, {
    path: "/en/user/payment/participant",
    component: _4b811fc0,
    name: "user-payment-participant___en"
  }, {
    path: "/en/user/profile/edit",
    component: _22d01dac,
    name: "user-profile-edit___en"
  }, {
    path: "/ru/user/payment/participant",
    component: _4b811fc0,
    name: "user-payment-participant___ru"
  }, {
    path: "/ru/user/profile/edit",
    component: _22d01dac,
    name: "user-profile-edit___ru"
  }, {
    path: "/en/auth/oauth/:provider?",
    component: _66977eab,
    name: "auth-oauth-provider___en"
  }, {
    path: "/ru/auth/oauth/:provider?",
    component: _66977eab,
    name: "auth-oauth-provider___ru"
  }, {
    path: "/auth/oauth/:provider?",
    component: _66977eab,
    name: "auth-oauth-provider___ru___default"
  }, {
    path: "/en/news/:alias",
    component: _075718c3,
    name: "news-alias___en"
  }, {
    path: "/en/program/:alias",
    component: _165d7e76,
    name: "program-alias___en"
  }, {
    path: "/en/speakers/:alias",
    component: _7cbab9a2,
    name: "speakers-alias___en"
  }, {
    path: "/en/visit/:id?",
    component: _c96f02a8,
    name: "visit-id___en"
  }, {
    path: "/ru/news/:alias",
    component: _075718c3,
    name: "news-alias___ru"
  }, {
    path: "/ru/program/:alias",
    component: _165d7e76,
    name: "program-alias___ru"
  }, {
    path: "/ru/speakers/:alias",
    component: _7cbab9a2,
    name: "speakers-alias___ru"
  }, {
    path: "/ru/visit/:id?",
    component: _c96f02a8,
    name: "visit-id___ru"
  }, {
    path: "/news/:alias",
    component: _075718c3,
    name: "news-alias___ru___default"
  }, {
    path: "/program/:alias",
    component: _165d7e76,
    name: "program-alias___ru___default"
  }, {
    path: "/speakers/:alias",
    component: _7cbab9a2,
    name: "speakers-alias___ru___default"
  }, {
    path: "/visit/:id?",
    component: _c96f02a8,
    name: "visit-id___ru___default"
  }, {
    path: "/en/*",
    component: _6401bcd6,
    name: "all___en"
  }, {
    path: "/ru/*",
    component: _6401bcd6,
    name: "all___ru"
  }, {
    path: "/",
    component: _ff52ccf0,
    name: "index___ru___default"
  }, {
    path: "/*",
    component: _6401bcd6,
    name: "all___ru___default"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
